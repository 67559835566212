<template>
  <div class="page">

    <!--关闭弹窗，居中对齐-->
    <div class="notice-wrapper" v-show="ui.noticeBarStatus">
      <div class="center">
        <el-button
            size="small"
            :plain="false"
            type="primary"
            style="opacity: 0.5;display: block;width: 100%;margin-top: 5px;white-space: nowrap;"
            @click="close()">
          <i class="fa fa-warning"></i> 请点击 " 对话框 " 右上角 <i class="fa fa-close"></i> 按钮，以
          <span style="color:red">" 正常关闭 "</span> 弹窗！
        </el-button>
      </div>
    </div>

    <!--统计-->
    <div class="statistics-wrapper">
      <div v-for="(item,index) in dataList" class="stat-list">
        <div class="sub-title">{{item['statVal']}}</div>
        <div class="title">{{item["title"]}}</div>
      </div>
    </div>

  </div>

</template>

<script>
import config from '/vue.config.js'
import {getTableCaption, statistics} from "@/api/public";

export default {
  name: "showStatisticsMain",
  data() {
    return {

      // 基础网址
      apiUrl: process.env.VUE_APP_BASE_API,

      // 界面元素状态
      ui: {
        noticeBarStatus: true,
      },

      // 输入参数
      caption: {
        tableName: '',
        tableAlias: '',
        tableTitle: '',
        keyIdName: '',
        id: '',
        title: '',
      },
      // 查询入参
      seekPo: {
        pageNum: 1,
        pageSize: 14,
        conditionAttach: {
          dateInterval: [],
        },
        condition: {},
        total: 0,
        sort: [
          {
            "orderBy": '',
            "sortOrder": "desc"
          }
        ],
      },
      // 数据集
      dataList: [],
    }
  },
  created() {

    // 初始化
    this.initParm();
    if (this.caption.tableName) {
      this.initCaption();
    }
  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  methods: {

    // 初始化参数
    initParm() {

      // 输入参数
      let e = this.$route.query;

      // 参数到模型
      for (let key in e) {
        if (key == "tableName") {
          let tableName = e[key];
          let tableAlias = tableName.replace(/_/g, "");
          tableAlias = tableAlias.toLowerCase();
          this.caption.tableAlias = tableAlias;
          this.caption[key] = tableName;
        } else if (key == "url") {
          this.caption[key] = e[key].replace(/_thumb/g, "");
        } else {
          this.caption[key] = e[key];
        }
      }

      // 查询参数
      let seekPo = window.parent.getSeekPo();
      this.seekPo = seekPo;

      this.setResize();
    },
    // 初始化数据格式
    initCaption() {
      // 数据格式

      getTableCaption(this.caption.tableName).then(res => {
        for (let key in res) this.caption[key] = res[key];
        this.initData();
      });
    },
    initData() {

      // 数据集
      statistics(this.caption.tableName, this.seekPo).then(res => {
        this.dataList = res;
        this.$forceUpdate();
      })
    },
    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        let width = document.documentElement.clientWidth;
        if (width < 768) {
          this.ui.labelWidth = '80px';
        } else {
          this.ui.labelWidth = '120px';
        }
      });
    },
    //后退
    goBack() {
      this.$router.go(-1);
    },
    // 关闭
    close() {
      window.parent.closeDialog();
    },

  },

}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  animation: page-fade-in 1s forwards;
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

/*--通知性质栏--*/
.notice-wrapper {

  margin: 10px;
  margin-top: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

}

/*--图片区域--*/
.statistics-wrapper {

  margin: 0px 10px;
  padding: 40px 20px;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
  background-color: #FFF;
  height: calc(100% - 150px);

  overflow-y: auto;

  background-image: url(../../static/images/bg_1.png);
  background-repeat: no-repeat;
  background-position: right bottom;

  .stat-list {

    float: left;
    display: inline-block;
    text-align: center;

    .sub-title {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;

      border: 1px solid #EEE;
      border-radius: 12px;

      background-image: linear-gradient(to top, #4094ff 5%, #F5F5F5 10%);
      text-shadow: 4px 4px rgba(197, 223, 248, 0.8);
      opacity: 0.8;

      font-size: 30px;
      color: #F56C6C;
      margin: auto;
    }

    .title {
      text-align: center;
      font-size: 12px;
      margin: auto;
      margin-top: 10px;
      color: #3a3a3a;
    }
  }

}

</style>